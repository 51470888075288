<template>
  <v-layout wrap>
    <v-flex xs12>
      <b>{{editedID}}</b>
    </v-flex>
    <v-flex xs12>
      <v-select
        v-model="selectedTag"
        :items="tags"
        label="Tag"
        outlined
        :disabled="actualURL ? true : false"
      />
    </v-flex>
    <v-flex xs12>
      <input type ="file" id ="image_input" accept ="image/jpeg, image/png, image/jpg">
      <center>
        <small><b>NOTA: </b>500px x 420px (.jpg)</small>
        <div id="display-image" v-show="imageBase64 || actualURL" />
      </center>
    </v-flex>
    <v-flex xs12>
      <action-buttons
        :onSaveItem="handleSave"
        :onClose="onClose"
        locale="es"
      />
    </v-flex>
  </v-layout>
</template>
<script>
import axios from 'axios'
import ActionButtons from '@/components/ActionButtons'
export default {
  components: {
    ActionButtons,
  },
  props: {
    editedID: {
      type: String,
      required: true,
    },
    actualURL: {
      type: String,
      default: null
    },
    actualTag: {
      type: String,
      required: true,
    },
    onClose: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    selectedTag: 'Wefamily',
    imageBase64: null,
  }),
  computed: {
    tags () {
      return [ 'Wefamily', 'Weculture', 'Westop', 'Wenjoy', 'Wemove', 'Weco', 'Weat']
    },
  },
  watch: {
    actualURL () {
      this.prepareImage()
    },
    actualTag () {
      this.prepareTag()
    },
  },
  mounted () {
    const image_input = document.querySelector("#image_input")

      image_input.addEventListener('change', () => {
      const file_reader = new FileReader()
      file_reader.addEventListener("load", () => {
        const uploaded_image = file_reader.result
        this.imageBase64 = uploaded_image
        document.querySelector("#display-image").style.backgroundImage = `url(${uploaded_image})`
      })
      file_reader.readAsDataURL(image_input.files[0])
    })
    this.prepareTag()
    this.prepareImage()
  },
  methods: {
    prepareTag () {
      this.selectedTag = this.actualTag
    },
    prepareImage () {
      document.querySelector("#display-image").style.backgroundImage = `url(${this.actualURL})`
    },
    handleSave () {
      const body = {
        id: this.editedID,
        imageBase64: this.imageBase64,
        tag: this.selectedTag
      }
      if (!this.imageBase64) {
        alert('no image')
        return
      }
      axios.post('https://www.dviewcontent.com/api/public/barcelo/upload-image', body)
        .then(() => {
          this.onClose(true)
        })
    },
  },
}
</script>
<style scoped>
#display-image{
  width: 500px;
  height: 420px;
  border: 2px solid black;
  background-position: center;
  background-size: cover;
}
</style>

